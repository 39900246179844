import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Products from "../components/products"
import { graphql } from "gatsby"
import img from "../images/5.png"

const Page = ({ data, pageContext }) => {
  const pageData = data.cms.page[0]
  const navigationItems = data.cms.navigation
  console.log(navigationItems)
  let pageComponent = <></>
  if (pageData.slug === "home") {
    pageComponent = (
      <div className="p-5">
        <div class="row d-flex aling-items-center">
          <div class="col-md-12">
            <div className="card mb-4">
              <div className="card-body d-flex justify-content-center">
                <img
                  className="img-fluid"
                  alt="Landu producten"
                  src={img}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    pageComponent = (
      <Products
        page={pageData}
        category={pageData.productcategory}
        products={data.cms.products}
      />
    )
  }

  return (
    <Layout langKey={pageContext.lang} navigationItems={navigationItems}>
      <SEO
        description={pageData.seo_description}
        title={pageData.seo_title}
        keywords={pageData.seo_keywords}
      />
      {pageComponent}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($lang: String, $id: Int, $language: String) {
    cms {
      page: pages(lang: $lang, id: $id) {
        id
        title
        seo_title
        seo_keywords
        seo_description
        slug
        body
        productcategory
      }
      navigation: pages(lang: $lang) {
        id
        title
        seo_title
        slug
      }
      products {
        title
        id
        image {
          title
          path
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
