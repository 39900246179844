import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProductModal from "./productModal"

const Products = ({ category, page }) => {
  const cmsData = useStaticQuery(graphql`
    query {
      cms {
        products {
          sku
          title
          id
          category
          attr_body
          attr_hook
          attr_wheel
          image {
            title
            path
          }
          pdf {
            title
            path
          }
          subproducts {
            sku
            attr_body
            attr_hook
            attr_wheel
            attr_bearing
            pdf {
              title
              path
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    filterProducts()
  }, [])
  const [openProduct, setOpenProduct] = useState()
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({
    attr_body: [],
    attr_hook: [],
    attr_wheel: [],
  })
  if (category === "trolleys") {
    category = "trolley"
  }
  if (category === "hang-systeem") {
    category = "hangingsystem"
  }
  if (category === "trolleys") {
    category = "trolley"
  }

  const products = cmsData.cms.products
  let filters = [
    {
      key: "attr_body",
      label: "Body",
      options: {
        short: "Short",
        long: "Long",
        nawi: "Nawi",
      },
    },
    {
      key: "attr_hook",
      label: "Hook Ø",
      options: {
        14: "Ø14",
        16: "Ø16",
        18: "Ø18",
      },
    },
    {
      key: "attr_wheel",
      label: "Axis Ø Wheel",
      options: {
        12: "Ø12",
        15: "Ø15",
      },
    },
  ]
  if (category != "trolley") {
    filters = []
  }
  const filterProducts = () => {
    let filProducts = products.filter(product => {
      if (product.category != category) {
        return false
      }

      return Object.entries(selectedFilters).every(([filterKey, options]) => {
        // Options active in filter
        let activeOptions = Object.keys(options).filter(
          optionKey => options[optionKey] === true
        )

        // Is filter active
        if (activeOptions.length > 0) {

          if (activeOptions.includes(product[filterKey])) {
            return true
          }
        } else {
          return true
        }
        return false
      })
      return true
    })
    setFilteredProducts(filProducts)
  }
  const renderFilters = () => {
    return Object.values(filters).map(filter => {
      return (
        <div key={filter.key}>
          <h6>{filter.label}</h6>
          {renderFilterOptions(filter)}
        </div>
      )
    })
  }
  useEffect(() => {
    filterProducts()
  }, [selectedFilters])
  const onChangeFilter = (e, filterKey) => {
    const optionKey = e.target.value
    setSelectedFilters(prevState => {
      const newState = Object.assign({}, prevState)
      newState[filterKey][optionKey] = !newState[filterKey][optionKey]
      return newState
    })
  }
  const renderFilterOptions = filter => {
    return Object.entries(filter.options).map(([optionKey, label]) => {
      return (
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value={optionKey}
            id="defaultCheck1"
            onChange={e => onChangeFilter(e, filter.key)}
          />

          <label class="form-check-label" for="defaultCheck1">
            {label}
          </label>
        </div>
      )
    })
  }

  const showProductModal = product => {
    setOpenProduct(product)
  }

  const closeProductModal = () => {
    setOpenProduct()
  }

  const renderProducts = () => {
    return filteredProducts.map(item => {
      if (item.image.length < 1) {
        return false
      }
      return (
        <div className="col-md-3" key={item.id}>
          <div
            className="product-card card mb-4"
            onClick={() => showProductModal(item)}
          >
            <div className="card-body">
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "260px" }}
              >
                <img
                  style={{ maxHeight: "260px", maxWidth: "100%" }}
                  alt={item.image[0].title}
                  src={"http://cms.landu.be/" + item.image[0].path}
                />
              </div>

              <h3 className="card-title fs-5">{item.title}</h3>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className="row">
      <ProductModal
        product={openProduct}
        closeModal={() => closeProductModal()}
      />
      {/* <div className="col-md-3">
        <h2>Filters</h2>
        {renderFilters()}
      </div> */}
      <div className="col-md-12">
        <h2 className="mb-5">{page.title}</h2>
        <div className="row">{renderProducts()}</div>
      </div>
    </div>
  )
}

export default Products
