import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"

const ProductModal = ({ product, closeModal }) => {
  const [show, setShow] = useState(false)
  const [filteredProduct, setFilteredProduct] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({
    attr_hook: 14,
    attr_wheel: 12,
    attr_bearing: 6301,
  })

  useEffect(() => {
    filterProducts()
  }, [selectedFilters])
  useEffect(() => {
    filterProducts()
  }, [product])
  const filterProducts = () => {
    
    if (!product || !product.subproducts) {
      return false
    }
    setFilteredProduct(null)
    

    let result = product.subproducts.some(subproduct => {
      if (
        subproduct.attr_hook == selectedFilters.attr_hook &&
        subproduct.attr_bearing == selectedFilters.attr_bearing &&
        subproduct.attr_wheel == selectedFilters.attr_wheel
      ) {
        console.log(subproduct, "found matching subproduct")
        setFilteredProduct(subproduct)
        return true
      }
    })

    if (result === false && selectedFilters['attr_bearing'] != '') {
      setSelectedFilters(prevState => {
        const newState = Object.assign({}, prevState)
        newState['attr_bearing'] = ''
        //newState['attr_wheel'] = ''
        return newState
      })
    }

  }

  const onChangeFilter = (e, filterKey) => {
    e.persist()
    setSelectedFilters(prevState => {
      const newState = Object.assign({}, prevState)
      newState[filterKey] = e.target.value
      return newState
    })
  }

  const handleClose = () => closeModal()
  const handleShow = () => setShow(true)
  if (!product) {
    return false
  }

  const renderFilteredProduct = () => {
    if (!filteredProduct) {
      return false
    }
    return (
      <>
        <p className="mt-3">
          Bestelcode: <b>{filteredProduct.sku}</b>
        </p>
        {renderDownloadPdf(filteredProduct)}
      </>
    )
  }

  const renderDownloadPdf = (product) => {
    if (!product.pdf || product.pdf.length < 1) {
      return false
    }
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"http://cms.landu.be/" + product.pdf[0].path}
        className="btn btn-primary text-white"
      >
        {" "}
        Download technische fiche
      </a>
    )
  }

  const renderTrolleyBody = () => {
    return (
      <>
        <label className="form-label mt-4 fw-bold">Ø Onderhaak</label>
        <select
          className="form-select"
          value={selectedFilters.attr_hook}
          onChange={e => onChangeFilter(e, "attr_hook")}
        >
          <option value=''></option>
          <option value="14">14</option>
          <option value="16">16</option>
          <option value="18">18</option>
        </select>
        <label className="form-label mt-4 fw-bold">Ø as</label>
        <select
          className="form-select"
          value={selectedFilters.attr_wheel}
          onChange={e => onChangeFilter(e, "attr_wheel")}
        >
          <option value=''></option>
          <option value="12">12</option>
          <option value="15">15</option>
        </select>
        <label className="form-label mt-4 fw-bold">Type lager</label>
        <select
          className="form-select mb-4"
          value={selectedFilters.attr_bearing}
          onChange={e => onChangeFilter(e, "attr_bearing")}
        >
                    <option value=''></option>

          <option disabled={selectedFilters.attr_wheel == 15} value="6301">W 6301-2RS1</option>
          <option disabled={selectedFilters.attr_wheel == 15} value="6201">W 6201-2RS1</option>
          <option disabled={selectedFilters.attr_wheel != 15} value="6202">W 6202-2RS1</option>
        </select>
        {renderFilteredProduct()}
      </>
    )
  }

  const renderProductBody = () => {
    return (
      <>
        <p className="mt-3">
          Bestelcode: <b>{product.sku}</b>
        </p>
        {renderDownloadPdf(product)}
      </>
    )
  }

  return (
    <>
      <Modal
        size="lg"
        show={product ? true : false}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title>{product.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 d-flex h-100 flex-column justify-content-end">
              {product.category === "trolley"
                ? renderTrolleyBody()
                : renderProductBody()}
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <img
                alt={product.image[0].title}
                src={"http://cms.landu.be/" + product.image[0].path}
                style={{ maxWidth: "100%" }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProductModal
